import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (
    unit: string,
    phase: T_Phase,
    plan: T_PlanTitle,
    clickable: boolean,
  ) => string;
  click: (plan: T_PlanTitle, clickable: boolean) => void;
};

const Phase7 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_7">
      <g id="PHASE-7-UNITS" className={S.Units}>
        <g id="UNITS-1-4">
          <g
            onClick={() => click("A2", false)}
            className={unitClasses("4", "Phase 7", "A2", false)}
            id="UNIT-4-A2"
          >
            <polygon
              className={S.Hot}
              points="261.8,72.6 259.5,72.6 259.5,55.9 218.3,55.9 218.3,91.2 227.3,91.2 227.3,94.2 259.5,94.2
					259.5,83.9 261.8,83.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 229.9956 73.3165)"
                  className={S.Plan}
                >
                  A2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 235.7612 85.3842)"
                className={S.Number}
              >
                4
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", false)}
            className={unitClasses("3", "Phase 7", "A3", false)}
            id="UNIT-3-A3"
          >
            <polygon
              className={S.Hot}
              points="177.1,94.2 177.1,55.9 218.3,55.9 218.3,91.2 209.3,91.2 209.3,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 188.6455 73.3165)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 194.7334 85.3842)"
                className={S.Number}
              >
                3
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", false)}
            className={unitClasses("2", "Phase 7", "A", false)}
            id="UNIT-2-A"
          >
            <polygon
              className={S.Hot}
              points="136,94.2 136,55.9 177.1,55.9 177.1,91.2 168.2,91.2 168.2,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 151.3462 73.3165)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 153.4868 85.3842)"
                className={S.Number}
              >
                2
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A1", false)}
            className={unitClasses("1", "Phase 7", "A1", false)}
            id="UNIT-1-A1"
          >
            <polygon
              className={S.Hot}
              points="94.8,94.2 94.8,55.9 136,55.9 136,91.2 127,91.2 127,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 107.4536 73.3165)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 113.3735 85.3842)"
                className={S.Number}
              >
                1
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-5-9">
          <g
            onClick={() => click("A4", false)}
            className={unitClasses("9", "Phase 7", "A4", false)}
            id="UNIT-9-A4"
          >
            <polygon
              className={S.Hot}
              points="538.2,72.6 535.9,72.6 535.9,55.9 494.6,55.9 494.6,91.2 503.6,91.2 503.6,94.2 535.9,94.2
					535.9,83.9 538.2,83.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 505.6512 73.3165)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 511.9852 85.3842)"
                className={S.Number}
              >
                9
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", false)}
            className={unitClasses("8", "Phase 7", "A3", false)}
            id="UNIT-8-A3"
          >
            <polygon
              className={S.Hot}
              points="453.2,94.2 453.2,55.9 494.6,55.9 494.6,91.2 485.6,91.2 485.6,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 464.7504 73.3165)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 470.7533 85.3842)"
                className={S.Number}
              >
                8
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", false)}
            className={unitClasses("7", "Phase 7", "A", false)}
            id="UNIT-7-A"
          >
            <polygon
              className={S.Hot}
              points="411.9,94.2 411.9,55.9 453.2,55.9 453.2,91.2 444.2,91.2 444.2,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 427.451 73.3165)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 429.6073 85.3842)"
                className={S.Number}
              >
                7
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", false)}
            className={unitClasses("6", "Phase 7", "A", false)}
            id="UNIT-6-A"
          >
            <polygon
              className={S.Hot}
              points="370.6,94.2 370.6,55.9 411.9,55.9 411.9,91.2 402.9,91.2 402.9,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 386.1342 73.3165)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 388.0345 85.3842)"
                className={S.Number}
              >
                6
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A1", false)}
            className={unitClasses("5", "Phase 7", "A1", false)}
            id="UNIT-5-A1"
          >
            <polygon
              className={S.Hot}
              points="327,72.6 329.3,72.6 329.3,55.9 370.6,55.9 370.6,91.2 361.6,91.2 361.6,94.2 329.3,94.2
					329.3,83.9 327,83.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 342.2416 73.3165)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 346.892 85.3842)"
                className={S.Number}
              >
                5
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-36-43">
          <g
            onClick={() => click("C5", false)}
            className={unitClasses("43", "Phase 7", "C5", false)}
            id="UNIT-43-C5"
          >
            <rect
              x="445.7"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 451.7921 162.3146)"
                  className={S.Plan}
                >
                  C5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 454.2197 174.3824)"
                className={S.Number}
              >
                43
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("42", "Phase 7", "C", false)}
            id="UNIT-42-C"
          >
            <rect
              x="414.3"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 425.1821 162.3146)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 423.6302 174.3824)"
                className={S.Number}
              >
                42
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("41", "Phase 7", "C", false)}
            id="UNIT-41-C"
          >
            <rect
              x="382.9"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 393.7677 162.2782)"
                className={S.Plan}
              >
                C
              </text>
            </g>
            <text
              transform="matrix(1 0 0 1 393.4209 174.3459)"
              className={S.Number}
            >
              41
            </text>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("40", "Phase 7", "C", false)}
            id="UNIT-40-C"
          >
            <rect
              x="351.4"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 362.353 162.3146)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 360.2211 174.3824)"
                className={S.Number}
              >
                40
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("39", "Phase 7", "C", false)}
            id="UNIT-39-C"
          >
            <rect
              x="320"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 330.939 162.3147)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 329.4369 174.3824)"
                className={S.Number}
              >
                39
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("38", "Phase 7", "C", false)}
            id="UNIT-38-C"
          >
            <rect
              x="288.6"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 299.5242 162.3147)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 298.1071 174.3824)"
                className={S.Number}
              >
                38
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C3", false)}
            className={unitClasses("37", "Phase 7", "C3", false)}
            id="UNIT-37-C3"
          >
            <rect
              x="257.2"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 264.0933 162.3147)"
                  className={S.Plan}
                >
                  C3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 266.8627 174.3824)"
                className={S.Number}
              >
                37
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C1", false)}
            className={unitClasses("36", "Phase 7", "C1", false)}
            id="UNIT-36-C1"
          >
            <rect
              x="225.8"
              y="138.4"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 234.1208 162.3147)"
                  className={S.Plan}
                >
                  C1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 235.193 174.3824)"
                className={S.Number}
              >
                36
              </text>
            </g>
          </g>
        </g>
      </g>
      <path
        id="PHASE-7-OUTLINE"
        className={S.Outline}
        d="M545.4,0H57.8c0,0,4.3,128.4,36.6,196.1h404.3v-77.6h46.6V0"
      />
      <g id="PHASE-7-LABEL" className={S.PhaseLabel}>
        <rect x="57.8" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 65.2288 15.0637)">PHASE 7</text>
      </g>
    </g>
  );
};

export default Phase7;
