import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (
    unit: string,
    phase: T_Phase,
    plan: T_PlanTitle,
    clickable: boolean,
  ) => string;
  click: (plan: T_PlanTitle, clickable: boolean) => void;
};

const Phase4 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_4">
      <g id="PHASE-4-UNITS" className={S.Units}>
        <g id="UNITS-81-86">
          <g
            onClick={() => click("A4", true)}
            className={unitClasses("86", "Phase 4", "A4", true)}
            id="UNIT-86-A4"
          >
            <polygon
              className={S.Hot}
              points="1281.2,141.6 1319.8,141.6 1319.8,174.3 1316.1,174.3 1316.1,182.9 1281.2,182.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1289.9122 160.492)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1293.0967 172.5597)"
                className={S.Number}
              >
                86
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", true)}
            className={unitClasses("85", "Phase 4", "A3", true)}
            id="UNIT-85-A3"
          >
            <polygon
              className={S.Hot}
              points="1281.2,224.2 1319.8,224.2 1319.8,191.4 1316.1,191.4 1316.1,182.9 1281.2,182.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1290.3282 201.7991)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1293.2715 213.8668)"
                className={S.Number}
              >
                85
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("84", "Phase 4", "A", true)}
            id="UNIT-84-A"
          >
            <polygon
              className={S.Hot}
              points="1281.2,265.5 1319.8,265.5 1319.8,232.7 1316.1,232.7 1316.1,224.2 1281.2,224.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1294.3458 243.1059)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1292.9766 255.1736)"
                className={S.Number}
              >
                84
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("83", "Phase 4", "A", true)}
            id="UNIT-83-A"
          >
            <polygon
              className={S.Hot}
              points="1281.2,306.8 1319.8,306.8 1319.8,274.1 1316.1,274.1 1316.1,265.5 1281.2,265.5 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1294.3458 284.413)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1293.2666 296.4807)"
                className={S.Number}
              >
                83
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("82", "Phase 4", "A", true)}
            id="UNIT-82-A"
          >
            <polygon
              className={S.Hot}
              points="1281.2,348.1 1319.8,348.1 1319.8,315.4 1316.1,315.4 1316.1,306.8 1281.2,306.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1294.3458 325.7202)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1293.3369 337.7878)"
                className={S.Number}
              >
                82
              </text>
            </g>
          </g>

          {/* Changed */}
          <g
            onClick={() => click("A2", true)}
            className={unitClasses("81", "Phase 4", "A2", true)}
            id="UNIT-81-A2"
          >
            <polygon
              className={S.Hot}
              points="1281.2,389.4 1319.8,389.4 1319.8,356.7 1316.1,356.7 1316.1,348.1 1281.2,348.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1290.0822 367.0273)"
                  className={S.Plan}
                >
                  A2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1294.542 379.095)"
                className={S.Number}
              >
                81
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-75-80">
          <g
            onClick={() => click("A1", true)}
            className={unitClasses("80", "Phase 4", "A1", true)}
            id="UNIT-80-A1"
          >
            <polygon
              className={S.Hot}
              points="1233,395.5 1194.4,395.5 1194.4,362.7 1198.1,362.7 1198.1,354.2 1233,354.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1207.6445 373.1014)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1208.6299 385.1692)"
                className={S.Number}
              >
                80
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("79", "Phase 4", "A", true)}
            id="UNIT-79-A"
          >
            <polygon
              className={S.Hot}
              points="1233,354.2 1194.4,354.2 1194.4,321.4 1198.1,321.4 1198.1,312.9 1233,312.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1210.2188 331.7948)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1209.1396 343.8625)"
                className={S.Number}
              >
                79
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("78", "Phase 4", "A", true)}
            id="UNIT-78-A"
          >
            <polygon
              className={S.Hot}
              points="1233,312.9 1194.4,312.9 1194.4,280.1 1198.1,280.1 1198.1,271.6 1233,271.6 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1210.2188 290.4879)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1209.2246 302.5556)"
                className={S.Number}
              >
                78
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("77", "Phase 4", "A", true)}
            id="UNIT-77-A"
          >
            <polygon
              className={S.Hot}
              points="1233,271.6 1194.4,271.6 1194.4,238.8 1198.1,238.8 1198.1,230.3 1233,230.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1210.2188 249.1808)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1209.3945 261.2485)"
                className={S.Number}
              >
                77
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", true)}
            className={unitClasses("76", "Phase 4", "A3", true)}
            id="UNIT-76-A3"
          >
            <polygon
              className={S.Hot}
              points="1233,230.3 1194.4,230.3 1194.4,197.5 1198.1,197.5 1198.1,188.9 1233,188.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1206.2012 207.874)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1209.1396 219.9417)"
                className={S.Number}
              >
                76
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A2", true)}
            className={unitClasses("75", "Phase 4", "A2", true)}
            id="UNIT-75-A2"
          >
            <polygon
              className={S.Hot}
              points="1233,147.6 1194.4,147.6 1194.4,180.4 1198.1,180.4 1198.1,188.9 1233,188.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1206.2344 166.5669)"
                  className={S.Plan}
                >
                  A2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1209.3145 178.6346)"
                className={S.Number}
              >
                75
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-69-74">
          <g
            onClick={() => click("A4", true)}
            className={unitClasses("74", "Phase 4", "A4", true)}
            id="UNIT-74-A4"
          >
            <polygon
              className={S.Hot}
              points="1095.9,137.6 1134.4,137.6 1134.4,170.3 1130.8,170.3 1130.8,178.9 1095.9,178.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1104.3925 156.5321)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1107.6267 168.5998)"
                className={S.Number}
              >
                74
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", true)}
            className={unitClasses("73", "Phase 4", "A3", true)}
            id="UNIT-73-A3"
          >
            <polygon
              className={S.Hot}
              points="1095.9,220.2 1134.4,220.2 1134.4,187.5 1130.8,187.5 1130.8,178.9 1095.9,178.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1104.8085 197.8391)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1107.9167 209.9068)"
                className={S.Number}
              >
                73
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("72", "Phase 4", "A", true)}
            id="UNIT-72-A"
          >
            <polygon
              className={S.Hot}
              points="1095.9,261.5 1134.4,261.5 1134.4,228.8 1130.8,228.8 1130.8,220.2 1095.9,220.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1108.826 239.1462)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1107.9871 251.2139)"
                className={S.Number}
              >
                72
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("71", "Phase 4", "A", true)}
            id="UNIT-71-A"
          >
            <polygon
              className={S.Hot}
              points="1095.9,302.8 1134.4,302.8 1134.4,270.1 1130.8,270.1 1130.8,261.5 1095.9,261.5 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1108.826 280.4533)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1109.1921 292.521)"
                className={S.Number}
              >
                71
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", true)}
            className={unitClasses("70", "Phase 4", "A", true)}
            id="UNIT-70-A"
          >
            <polygon
              className={S.Hot}
              points="1095.9,344.1 1134.4,344.1 1134.4,311.4 1130.8,311.4 1130.8,302.8 1095.9,302.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1108.826 321.7597)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1107.407 333.8274)"
                className={S.Number}
              >
                70
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A1", true)}
            className={unitClasses("69", "Phase 4", "A1", true)}
            id="UNIT-69-A1"
          >
            <polygon
              className={S.Hot}
              points="1095.9,385.4 1134.4,385.4 1134.4,352.7 1130.8,352.7 1130.8,344.1 1095.9,344.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1106.2518 363.0668)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1107.4919 375.1346)"
                className={S.Number}
              >
                69
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-7-13">
          <g
            onClick={() => click("A5", true)}
            className={unitClasses("13", "Phase 4", "A5", true)}
            id="UNIT-13-A5"
          >
            <polygon
              className={S.Hot}
              points="1357.8,55.9 1357.8,52.4 1371,52.4 1371,55.9 1376.2,55.9 1376.2,94.2 1343.9,94.2 1343.9,91.2
					1334.9,91.2 1334.9,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1346.3508 73.3165)"
                  className={S.Plan}
                >
                  A5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1350.6819 85.3842)"
                className={S.Number}
              >
                13
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A6", true)}
            className={unitClasses("12", "Phase 4", "A6", true)}
            id="UNIT-12-A6"
          >
            <polygon
              className={S.Hot}
              points="1311.9,55.9 1311.9,52.4 1298.7,52.4 1298.7,55.9 1293.6,55.9 1293.6,94.2 1325.9,94.2
					1325.9,91.2 1334.9,91.2 1334.9,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1304.8201 73.3165)"
                  className={S.Plan}
                >
                  A6
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1309.4343 85.3842)"
                className={S.Number}
              >
                12
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7", true)}
            className={unitClasses("11", "Phase 4", "A7", true)}
            id="UNIT-11-A7"
          >
            <polygon
              className={S.Hot}
              points="1270.6,55.9 1270.6,52.4 1257.4,52.4 1257.4,55.9 1252.2,55.9 1252.2,94.2 1284.6,94.2
					1284.6,91.2 1293.6,91.2 1293.6,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1263.8079 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1269.3225 85.3842)"
                className={S.Number}
              >
                11
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7", true)}
            className={unitClasses("10", "Phase 4", "A7", true)}
            id="UNIT-10-A7"
          >
            <polygon
              className={S.Hot}
              points="1229.3,55.9 1229.3,52.4 1216.1,52.4 1216.1,55.9 1210.9,55.9 1210.9,94.2 1243.2,94.2
					1243.2,91.2 1252.2,91.2 1252.2,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1222.491 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1226.2205 85.3842)"
                className={S.Number}
              >
                10
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7", true)}
            className={unitClasses("9", "Phase 4", "A7", true)}
            id="UNIT-9-A7"
          >
            <polygon
              className={S.Hot}
              points="1188,55.9 1188,52.4 1174.8,52.4 1174.8,55.9 1169.6,55.9 1169.6,94.2 1201.9,94.2 1201.9,91.2
					1210.9,91.2 1210.9,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1181.1741 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1187.0334 85.3842)"
                className={S.Number}
              >
                9
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7", true)}
            className={unitClasses("8", "Phase 4", "A7", true)}
            id="UNIT-8-A7"
          >
            <polygon
              className={S.Hot}
              points="1146.7,55.9 1146.7,52.4 1133.5,52.4 1133.5,55.9 1128.3,55.9 1128.3,94.2 1160.6,94.2
					1160.6,91.2 1169.6,91.2 1169.6,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1139.8572 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1145.8015 85.3842)"
                className={S.Number}
              >
                8
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A8", true)}
            className={unitClasses("7", "Phase 4", "A8", true)}
            id="UNIT-7-A8"
          >
            <polygon
              className={S.Hot}
              points="1105.4,55.9 1105.4,52.4 1092.1,52.4 1092.1,55.9 1087,55.9 1087,94.2 1119.3,94.2 1119.3,91.2
					1128.3,91.2 1128.3,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1098.4031 73.3165)"
                  className={S.Plan}
                >
                  A8
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1104.6541 85.3842)"
                className={S.Number}
              >
                7
              </text>
            </g>
          </g>
        </g>
      </g>
      <polygon
        id="PHASE-4-OUTLINE"
        className={S.Outline}
        points="1069,0 1384.5,0 1384.5,122 1342.9,122 1342.9,420.3 1069,420.3 	"
      />
      <g id="PHASE-4-LABEL" className={S.PhaseLabel}>
        <rect x="1069" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 1076.4541 15.0637)">PHASE 4</text>
      </g>
    </g>
  );
};

export default Phase4;
