import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (
    unit: string,
    phase: T_Phase,
    plan: T_PlanTitle,
    clickable: boolean,
  ) => string;
  click: (plan: T_PlanTitle, clickable: boolean) => void;
};

const Phase8 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_8">
      <g id="PHASE-8-UNITS" className={S.Units}>
        <g id="UNITS-10-13">
          <g
            onClick={() => click("A4", false)}
            className={unitClasses("13", "Phase 8", "A4", false)}
            id="UNIT-13-A4"
          >
            <polygon
              className={S.Hot}
              points="720.5,72.6 718.2,72.6 718.2,55.9 676.8,55.9 676.8,91.2 685.9,91.2 685.9,94.2 718.2,94.2
					718.2,83.9 720.5,83.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 689.082 73.3165)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 693.7968 85.3842)"
                className={S.Number}
              >
                13
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3", false)}
            className={unitClasses("12", "Phase 8", "A3", false)}
            id="UNIT-12-A3"
          >
            <polygon
              className={S.Hot}
              points="635.5,94.2 635.5,55.9 676.8,55.9 676.8,91.2 667.8,91.2 667.8,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 648.1811 73.3165)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 652.5492 85.3842)"
                className={S.Number}
              >
                12
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A", false)}
            className={unitClasses("11", "Phase 8", "A", false)}
            id="UNIT-11-A"
          >
            <polygon
              className={S.Hot}
              points="594.2,94.2 594.2,55.9 635.5,55.9 635.5,91.2 626.5,91.2 626.5,94.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 610.8818 73.3165)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 612.4374 85.3842)"
                className={S.Number}
              >
                11
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A1", false)}
            className={unitClasses("10", "Phase 8", "A1", false)}
            id="UNIT-10-A1"
          >
            <polygon
              className={S.Hot}
              points="550.6,72.6 552.9,72.6 552.9,55.9 594.2,55.9 594.2,91.2 585.2,91.2 585.2,94.2 552.9,94.2
					552.9,83.9 550.6,83.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 566.9892 73.3165)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 569.3339 85.3842)"
                className={S.Number}
              >
                10
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-50-60">
          <g
            onClick={() => click("E3A", false)}
            className={unitClasses("60", "Phase 8", "E3A", false)}
            id="UNIT-60-E3A"
          >
            <polygon
              className={S.Hot}
              points="569.8,316.4 569.8,354.8 618.5,354.8 618.5,322.9 615.2,322.9 615.2,316.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 579.5991 336.0625)"
                  className={S.Plan}
                >
                  E3A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 586.2972 348.1302)"
                className={S.Number}
              >
                60
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("59", "Phase 8", "E", false)}
            id="UNIT-59-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,278 569.8,316.4 618.5,316.4 618.5,284.5 615.2,284.5 615.2,278 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 588.7505 297.7382)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 586.8119 309.8058)"
                className={S.Number}
              >
                59
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("58", "Phase 8", "E", false)}
            id="UNIT-58-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,239.6 569.8,278 618.5,278 618.5,246.1 615.2,246.1 615.2,239.6 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 588.7505 259.4139)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 586.8969 271.4816)"
                className={S.Number}
              >
                58
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("57", "Phase 8", "E", false)}
            id="UNIT-57-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,201.2 569.8,239.6 618.5,239.6 618.5,207.7 615.2,207.7 615.2,201.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 588.7505 221.0897)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 587.0668 233.1574)"
                className={S.Number}
              >
                57
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E3A", false)}
            className={unitClasses("56", "Phase 8", "E3A", false)}
            id="UNIT-56-E3A"
          >
            <polygon
              className={S.Hot}
              points="569.8,162.8 569.8,201.2 618.5,201.2 618.5,169.4 615.2,169.4 615.2,162.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 579.5991 182.7655)"
                  className={S.Plan}
                >
                  E3A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 586.8119 194.8332)"
                className={S.Number}
              >
                56
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E3A", false)}
            className={unitClasses("55", "Phase 8", "E3A", false)}
            id="UNIT-55-E3A"
          >
            <polygon
              className={S.Hot}
              points="569.8,335.6 569.8,374 521.2,374 521.2,342.1 524.5,342.1 524.5,335.6 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 532.2602 353.0752)"
                  className={S.Plan}
                >
                  E3A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 539.6478 365.1429)"
                className={S.Number}
              >
                55
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("54", "Phase 8", "E", false)}
            id="UNIT-54-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,297.2 569.8,335.6 521.2,335.6 521.2,303.7 524.5,303.7 524.5,297.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 541.4116 314.6826)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 539.3528 326.7504)"
                className={S.Number}
              >
                54
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("53", "Phase 8", "E", false)}
            id="UNIT-53-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,258.8 569.8,297.2 521.2,297.2 521.2,265.3 524.5,265.3 524.5,258.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 541.4116 276.29)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 539.6429 288.3577)"
                className={S.Number}
              >
                53
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("52", "Phase 8", "E", false)}
            id="UNIT-52-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,220.4 569.8,258.8 521.2,258.8 521.2,226.9 524.5,226.9 524.5,220.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 541.4116 237.8976)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 539.7132 249.9653)"
                className={S.Number}
              >
                52
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E", false)}
            className={unitClasses("51", "Phase 8", "E", false)}
            id="UNIT-51-E"
          >
            <polygon
              className={S.Hot}
              points="569.8,182 569.8,220.4 521.2,220.4 521.2,188.5 524.5,188.5 524.5,182 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 541.4116 199.5052)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 540.9183 211.5729)"
                className={S.Number}
              >
                51
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E4", false)}
            className={unitClasses("50", "Phase 8", "E4", false)}
            id="UNIT-50-E4"
          >
            <polygon
              className={S.Hot}
              points="569.8,182 569.8,143.6 521.2,143.6 521.2,175.5 524.5,175.5 524.5,182 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 536.9789 161.1128)"
                  className={S.Plan}
                >
                  E4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 539.1331 173.1805)"
                className={S.Number}
              >
                50
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-14-21">
          <g
            onClick={() => click("C2", false)}
            className={unitClasses("21", "Phase 8", "C2", false)}
            id="UNIT-21-C2"
          >
            <rect
              x="674.5"
              y="347.6"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1062.7758 -317.7305)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 681.7251 372.4082)"
                  className={S.Plan}
                >
                  C2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.722 384.4759)"
                className={S.Number}
              >
                21
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C3", false)}
            className={unitClasses("20", "Phase 8", "C3", false)}
            id="UNIT-20-C3"
          >
            <rect
              x="674.5"
              y="316.2"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1031.3612 -349.1451)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 681.6929 340.957)"
                  className={S.Plan}
                >
                  C3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 683.9369 353.0247)"
                className={S.Number}
              >
                20
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("19", "Phase 8", "C", false)}
            id="UNIT-19-C"
          >
            <rect
              x="674.5"
              y="284.7"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 999.9467 -380.5597)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 685.7095 309.5048)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.4828 321.5724)"
                className={S.Number}
              >
                19
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("18", "Phase 8", "C", false)}
            id="UNIT-18-C"
          >
            <rect
              x="674.5"
              y="253.3"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 968.5321 -411.9742)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 685.7095 278.0535)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.5677 290.1212)"
                className={S.Number}
              >
                18
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("17", "Phase 8", "C", false)}
            id="UNIT-17-C"
          >
            <rect
              x="674.5"
              y="221.9"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 937.1176 -443.3888)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 685.7095 246.6018)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.7377 258.6695)"
                className={S.Number}
              >
                17
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("16", "Phase 8", "C", false)}
            id="UNIT-16-C"
          >
            <rect
              x="674.5"
              y="190.5"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 905.703 -474.8033)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 685.7095 215.1501)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.4828 227.2178)"
                className={S.Number}
              >
                16
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("15", "Phase 8", "C", false)}
            id="UNIT-15-C"
          >
            <rect
              x="674.5"
              y="159.1"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 874.2885 -506.2179)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 685.7095 183.6984)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.6576 195.7661)"
                className={S.Number}
              >
                15
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C4", false)}
            className={unitClasses("14", "Phase 8", "C4", false)}
            id="UNIT-14-C4"
          >
            <rect
              x="674.5"
              y="127.7"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 842.8739 -537.6324)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 681.2769 152.2469)"
                  className={S.Plan}
                >
                  C4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 685.3627 164.3146)"
                className={S.Number}
              >
                14
              </text>
            </g>
          </g>
        </g>
      </g>
      <path
        id="PHASE-8-OUTLINE"
        className={S.Outline}
        d="M741.7,0v416.6c0,0-151.6-10.6-185.7-13c-34.2-2.5-57.1-15.5-57.1-15.5V118.5h46.6V0
		H741.7z"
      />
      <g id="PHASE-8-LABEL" className={S.PhaseLabel}>
        <rect x="545.4" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 552.8064 15.0637)">PHASE 8</text>
      </g>
    </g>
  );
};

export default Phase8;
