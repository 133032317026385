import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (
    unit: string,
    phase: T_Phase,
    plan: T_PlanTitle,
    clickable: boolean,
  ) => string;
  click: (plan: T_PlanTitle, clickable: boolean) => void;
};

const Phase9 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_9">
      <g id="PHASE-9-UNITS" className={S.Units}>
        <g id="UNITS-34-35">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("35", "Phase 9", "D1", false)}
            id="UNIT-35-D1"
          >
            <polygon
              className={S.Hot}
              points="200.8,224 181.3,199.1 179.5,200.5 175.3,195.1 143,220.4 147.2,225.8 145.8,227 165.2,251.8
					"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 161.6532 223.4437)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 164.6619 235.5114)"
                className={S.Number}
              >
                35
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("34", "Phase 9", "D1", false)}
            id="UNIT-34-D1"
          >
            <polygon
              className={S.Hot}
              points="200.8,224 220.2,248.8 218.4,250.2 222.7,255.6 190.4,280.9 186.1,275.5 184.7,276.6 165.2,251.8
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 185.8049 251.6357)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 188.5187 263.7034)"
                className={S.Number}
              >
                34
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-32-33">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("33", "Phase 9", "D1", false)}
            id="UNIT-33-D1"
          >
            <polygon
              className={S.Hot}
              points="257.9,296.1 237.7,271.8 236,273.3 231.6,267.9 200,294.1 204.4,299.4 203,300.6 223.1,324.9
					"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 219.8087 295.6517)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 222.8125 307.7194)"
                className={S.Number}
              >
                33
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("32", "Phase 9", "D1", false)}
            id="UNIT-32-D1"
          >
            <polygon
              className={S.Hot}
              points="257.9,296.1 278,320.3 276.2,321.8 280.6,327.1 249.1,353.3 244.7,348 243.3,349.1 223.1,324.9
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 243.906 324.6833)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 246.9801 336.751)"
                className={S.Number}
              >
                32
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-30-31">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("31", "Phase 9", "D1", false)}
            id="UNIT-31-D1"
          >
            <polygon
              className={S.Hot}
              points="326,351.6 297.2,338.8 296.3,340.9 290,338.1 273.3,375.5 279.6,378.4 278.9,380 307.7,392.8
					"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 291.9872 364.8719)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 294.7908 376.9395)"
                className={S.Number}
              >
                31
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D5", false)}
            className={unitClasses("30", "Phase 9", "D5", false)}
            id="UNIT-30-D5"
          >
            <polygon
              className={S.Hot}
              points="326,351.6 354.8,364.5 353.9,366.5 360.2,369.3 343.5,406.8 337.2,404 336.5,405.7 307.7,392.8
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 324.5709 380.5076)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 327.2788 392.5753)"
                className={S.Number}
              >
                30
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-28-29">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("29", "Phase 9", "D1", false)}
            id="UNIT-29-D1"
          >
            <polygon
              className={S.Hot}
              points="411.2,386.4 381.7,375.3 380.9,377.4 374.4,375 359.9,413.4 366.4,415.8 365.7,417.5 395.2,428.6
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 378.2651 400.4187)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 379.6937 412.4864)"
                className={S.Number}
              >
                29
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("28", "Phase 9", "D1", false)}
            id="UNIT-28-D1"
          >
            <polygon
              className={S.Hot}
              points="411.2,386.4 440.7,397.5 439.9,399.7 446.3,402.1 431.8,440.5 425.4,438 424.7,439.8 395.2,428.6
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 413.1237 414.6825)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 414.6372 426.7502)"
                className={S.Number}
              >
                28
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-26-27">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("27", "Phase 9", "D1", false)}
            id="UNIT-27-D1"
          >
            <polygon
              className={S.Hot}
              points="498.5,417.3 468.2,408.7 467.6,410.9 460.9,409.1 449.8,448.5 456.4,450.4 455.9,452.1
					486.2,460.7 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 466.4907 432.9155)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 468.1742 444.9832)"
                className={S.Number}
              >
                27
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("26", "Phase 9", "D1", false)}
            id="UNIT-26-D1"
          >
            <polygon
              className={S.Hot}
              points="498.5,417.3 528.9,425.9 528.2,428.1 534.9,430 523.7,469.5 517.1,467.6 516.6,469.4 486.2,460.7
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 501.1451 444.1561)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 502.5737 456.2238)"
                className={S.Number}
              >
                26
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-24-25">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("25", "Phase 9", "D1", false)}
            id="UNIT-25-D1"
          >
            <polygon
              className={S.Hot}
              points="587.8,434.7 556.4,431.9 556.2,434.2 549.3,433.6 545.8,474.5 552.6,475.1 552.5,476.9
					583.9,479.6 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 559.1407 454.7165)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 560.7441 466.7842)"
                className={S.Number}
              >
                25
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("24", "Phase 9", "D1", false)}
            id="UNIT-24-D1"
          >
            <polygon
              className={S.Hot}
              points="587.8,434.7 619.2,437.4 619,439.7 625.9,440.3 622.3,481.2 615.5,480.6 615.3,482.4 583.9,479.6
									"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 597.2456 458.4201)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 598.5541 470.4878)"
                className={S.Number}
              >
                24
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-22-23">
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("23", "Phase 9", "D1", false)}
            id="UNIT-23-D1"
          >
            <polygon
              className={S.Hot}
              points="680.6,437.9 649.1,437.9 649.1,440.2 642.2,440.2 642.2,481.2 649.1,481.2 649.1,483.1
					680.6,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 653.9173 460.7483)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 655.5158 472.8159)"
                className={S.Number}
              >
                23
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1", false)}
            className={unitClasses("22", "Phase 9", "D1", false)}
            id="UNIT-22-D1"
          >
            <polygon
              className={S.Hot}
              points="680.6,437.9 712.2,437.9 712.2,440.2 719.1,440.2 719.1,481.2 712.2,481.2 712.2,483.1
					680.6,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 692.059 460.7483)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 693.7268 472.8159)"
                className={S.Number}
              >
                22
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-44-49">
          <g
            onClick={() => click("C2", false)}
            className={unitClasses("49", "Phase 9", "C2", false)}
            id="UNIT-49-C2"
          >
            <rect
              x="436.2"
              y="295.1"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 707.2282 811.5745)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 443.1277 319.6496)"
                  className={S.Plan}
                >
                  C2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 445.3199 331.7173)"
                className={S.Number}
              >
                49
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C3", false)}
            className={unitClasses("48", "Phase 9", "C3", false)}
            id="UNIT-48-C3"
          >
            <rect
              x="408.3"
              y="280.8"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 661.0507 771.6224)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 415.145 305.2007)"
                  className={S.Plan}
                >
                  C3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 417.4545 317.2684)"
                className={S.Number}
              >
                48
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("47", "Phase 9", "C", false)}
            id="UNIT-47-C"
          >
            <rect
              x="380.4"
              y="266.4"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 614.8732 731.6702)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 391.2112 290.7518)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 389.674 302.8195)"
                className={S.Number}
              >
                47
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("46", "Phase 9", "C", false)}
            id="UNIT-46-C"
          >
            <rect
              x="352.5"
              y="252"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 568.6956 691.718)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 363.2604 276.3028)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 361.4683 288.3705)"
                className={S.Number}
              >
                46
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C", false)}
            className={unitClasses("45", "Phase 9", "C", false)}
            id="UNIT-45-C"
          >
            <rect
              x="324.5"
              y="237.6"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 522.5181 651.7659)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 335.3097 261.8539)"
                  className={S.Plan}
                >
                  C
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 333.6924 273.9216)"
                className={S.Number}
              >
                45
              </text>
            </g>
          </g>
          <g
            onClick={() => click("C4", false)}
            className={unitClasses("44", "Phase 9", "C4", false)}
            id="UNIT-44-C4"
          >
            <rect
              x="296.6"
              y="223.2"
              transform="matrix(-0.8891 -0.4578 0.4578 -0.8891 476.3405 611.8137)"
              className={S.Hot}
              width="31.4"
              height="49.9"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 302.9268 247.4049)"
                  className={S.Plan}
                >
                  C4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 305.4471 259.4726)"
                className={S.Number}
              >
                44
              </text>
            </g>
          </g>
        </g>
      </g>
      <path
        id="PHASE-9-OUTLINE"
        className={S.Outline}
        d="M741.7,0v416.6c0,0-151.6-10.6-185.7-13S498.8,388,498.8,388V196.1H94.4
		C62.1,128.4,57.8,0,57.8,0H0v570h795.7V0H741.7z"
      />
      <g id="PHASE-9-LABEL" className={S.PhaseLabel}>
        <rect y="549.3" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 7.4275 564.3414)">PHASE 9</text>
      </g>
    </g>
  );
};

export default Phase9;
